<script>
import BaseIcon from "../components/icons/BaseIcon";
import Logo from "../components/icons/BaseLogo";
import SocialGoogle from "../components/icons/SocialGoogle";
import SocialVk from "../components/icons/SocialVk";
import SocialOk from "../components/icons/SocialOk";
//import SocialFb from "../components/icons/SocialFb";
import SuccessIcon from "../components/icons/BaseSuccess"
import BaseInput from "../components/BaseInput";
import {popupAuth, windowParameters} from "@/api";
import {createNamespacedHelpers} from "vuex";
import {API} from "@/plugins/axios";

const {mapActions} = createNamespacedHelpers('authStore')

export default {
  data() {
    return {
      states: {
        register: true,
        moreSocial: false,
      },
      formData: {name: "", email: "", password: ""},
      formError: "",
      forgotModal: {
        email: "",
        show: false,
        load: false,
        success: false,
      }

    }
  },
  components: {
    BaseIcon,
    Logo,
    SocialGoogle,
    SocialVk,
    SocialOk,
    //SocialFb,
    SuccessIcon,
    BaseInput,
  },
  methods: {
    getProjects() {
      this.$API.get("projects").then((response) => {
        this.$store.dispatch("projectsUpdate", response.data);
      })
    },
    resetPassword() {
      let formData = new FormData();
      formData.append('email', this.forgotModal.email);
      this.forgotModal.load = true;
      this.$API.post('auth/forgot', formData).then(() => {
        this.forgotModal.load = false;
        this.forgotModal.success = true;
        setTimeout(() => {
          this.forgotModal.show = false;
          this.forgotModal.load = false;
          this.forgotModal.success = false;
        }, 3000)
      });
    },
    provideAuth(syncLocalStorage=true) {
      if (syncLocalStorage) {
        this.authLocalStorage();
      }
      API.defaults.headers.Authorization = "Token " + localStorage.token;
      if (this.$route.query.invite) {
        let inviteData = new FormData();
        inviteData.append("invite", this.$route.query.invite);
        this.$API
            .post("projects/team/use_team_invite", inviteData)
            .then((response) => {
              this.$router.push({
                name: "ProjectPublications",
                params: {currentProject: response.data.project},
              });
            });
      } else {
        this.$router.replace("/");
      }
      this.getProjects();
    },
    vkAuth() {
      this.$API.post("vk.userAuth").then((response) => {
        let wnd = window.open(
            response.data.redirectLink,
            "vkPopup",
            windowParameters
        );
        popupAuth(
            wnd,
            "vkAuth",
            () => {
              this.provideAuth();
              localStorage.vkAuth = false;
            },
            () => {
              alert("Ошибка при авторизации");
            }
        );
      });
    },
    okAuth() {
      this.$API.post("ok.userAuth").then((response) => {
        let wnd = window.open(
            response.data.redirectLink,
            "okPopup",
            windowParameters
        );
        popupAuth(
            wnd,
            "okAuth",
            () => {
              this.provideAuth();
              localStorage.okAuth = false;
            },
            () => {
              alert("Ошибка при авторизации");
            }
        );
      });
    },
    async processForm(e) {
      e.preventDefault();
      let formData = new FormData(this.$refs.form);
      await this.$API
          .post("auth/" + (this.states.register ? "register" : 'login'), formData)
          .then((response) => {
            this.auth(response.data);
            //this.$refs.formMessage.classList.remove("error");
            this.provideAuth(false);
          })
          .catch((error) => {
            this.formError = error.response.data.message;
          });
    },
    ...mapActions([
      'auth',
      'authLocalStorage',
    ])
  },
}
</script>

<template>
  <div class="sp-auth">
    <div class="sp-auth-header">
      <base-icon icon-name="#всепоплану" width="215" height="20">
        <logo/>
      </base-icon>
      <a href="https://placestart.ru" target="blank" class="sp-auth-header__made">
        <span class="sp-auth-header__description">Сделано специально для вас</span>
      </a>
    </div>
    <div class="sp-auth-wrapper">
      <div class="sp-auth-page">
        <div class="sp-auth-content">
          <h1 class="sp-auth__title">
            Добро пожаловать на сервис планирования публикаций в социальных сетях
          </h1>
          <p class="sp-auth__description"
             :class="{'sp-auth__description_login': !states.register}"
          >
            {{
              states.register ? "Процедура регистрации займет у вас меньше минуты"
                  : "Настройте командную работу и обсуждайте посты с коллегами и заказчиками"
            }}
          </p>
        </div>
        <div class="sp-auth-form">
          <div class="sp-auth-form-select">
            <div class="sp-pointer sp-auth-form-select__item"
                 :class="{'sp-auth-form-select__item--active': states.register}"
                 @click="states.register = true"
            >
              Регистрация
            </div>
            <div class="sp-pointer sp-auth-form-select__item"
                 :class="{'sp-auth-form-select__item--active': !states.register}"
                 @click="states.register = false"
            >
              Вход
            </div>
          </div>
          <p class="sp-auth__label">Продолжить с</p>
          <div class="sp-auth-form-socials">
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_google" style="display: none">
              <base-icon icon-name="google" width="38" height="38">
                <social-google/>
              </base-icon>
              Google
            </div>
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_vk"
                 @click="vkAuth"
            >
              <base-icon icon-name="vk" width="45" height="45">
                <social-vk/>
              </base-icon>
              Вконтакте
            </div>
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_ok"
                 @click="okAuth"
            >
              <base-icon icon-name="ok" width="45" height="45">
                <social-ok/>
              </base-icon>
              Одноклассники
            </div>
            <!--                        <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_more"-->
            <!--                             v-show="!states.moreSocial"-->
            <!--                             @click="states.moreSocial = !states.moreSocial"-->
            <!--                             -->
            <!--                        >-->
            <!--                            + Показать еще-->
            <!--                        </div>-->
            <!--                        <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_fb"-->
            <!--                             v-show="states.moreSocial"-->
            <!--                        >-->
            <!--                            <base-icon icon-name="fb" width="45" height="45">-->
            <!--                                <social-fb/>-->
            <!--                            </base-icon>-->
            <!--                            Facebook-->
            <!--                        </div>-->
            <!--                  -->

          </div>
          <p class="sp-auth__label">или используйте электронную почту</p>
          <form class="sp-auth-form-items" ref="form" @submit="processForm">
            <base-input id="authName" name="name" label="Ваше имя" :auth="true"
                        v-if="states.register" :error-message="formError"
                        v-model="formData.name"
            />
            <base-input id="authEmail" type="email" name="email" label="Email" :auth="true"
                        :error-message="formError"
                        v-model="formData.email"
            />
            <base-input id="authPwd" type="password" name="password" label="Пароль" :auth="true"
                        :error-message="formError"
                        v-model="formData.password"
            />
            <button class="sp-btn sp-auth__submit">
              {{ states.register ? "Зарегистрироваться" : "Войти" }}
            </button>
            <p class="sp-auth__forgot sp-btn"
               v-show="!states.register"
               @click="forgotModal.show = true"
            >
              Забыли пароль?
            </p>
            <div class="sp-auth-personal">
              <p class="sp-auth-personal__text">
                Нажимая на кнопку, вы даете согласие на <a
                  class="sp-auth-personal__text sp-auth-personal__link" href="#" target="blank">обработку
                персональных данных</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <img class="sp-auth__bg" src="https://localhost:8000/static/images/плюшки.png" alt="">
    <div class="sp-auth__copyright">
      © 2021 Все права защищены
    </div>
    <Modal v-model="forgotModal.show"
           :close="() => (forgotModal.show = false)"
    >
      <div class="sp-modal sp-auth-modal-forgot"
           :class="{'sp-modal--load': forgotModal.load, 'sp-modal--success': forgotModal.success}"
      >
        <div class="sp-modal-main">
          <p class="sp-modal__title sp-pub_editor-template__title">Сброс пароля</p>
          <base-input name="email" label="Введите email"
                      v-model="forgotModal.email"
          >
          </base-input>
          <button class="sp-btn sp-btn--main sp-auth-modal-forgot__btn"
                  @click.prevent="resetPassword"
          >
            Сбросить пароль
          </button>
        </div>
        <div class="sp-modal-load">
          <div class="sp-preloader"></div>
        </div>
        <div class="sp-modal-success">
          <base-icon height="100" width="100" view-box="0 0 20 20">
            <success-icon/>
          </base-icon>
          <p class="sp-modal__description">Новый пароль отправлен на почту.</p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-auth {
  flex: 1;
  background: linear-gradient(99.65deg, #20457A -22.15%, #388B59 41.92%, #AFD049 86.61%, #D8F71F 120.88%);

  &__forgot {
    margin-top: 12px;
    @include font(14, 16, null);
    text-decoration: underline;
  }

  &__label {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $text;
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    height: 100%;
    user-select: none;
  }

  &__copyright {
    position: absolute;
    left: 49px;
    bottom: 38px;
    @include font(13, 18, null, $white)
  }

  &__submit {
    margin-top: 20px;
    @include font(15, 20, bold, $white);
    background: $mainBtn;
    height: 60px;
    border: none;

    &:hover {
      background: $mainBtnHover;
    }

    &:focus {
      border: 1px solid $borderFocused;
    }
  }

  &__title {
    font-weight: bold;
    @include font(47, 57);
    color: $white;
  }

  &-modal {
    &-forgot {
      width: 400px;
      padding: 30px 40px;

      &__btn {
        width: 100%;
        height: 50px;
        margin-top: 12px;
      }
    }
  }

  &-personal {
    margin-top: 12px;
    display: flex;
    justify-content: center;

    &__text {
      width: 426px;
      @include font(14, 20, normal, $fgText);
      text-align: center;
    }

    &__link {
      color: $mainBtn;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $mainBtnHover;
      }
    }
  }

  &__description {
    margin-top: 35px;
    width: 340px;
    @include font(21, 29, null, $white);

    &_login {
      width: 461px;
    }
  }

  &-form {
    z-index: 1;
    width: 591px;
    border-radius: 8px;
    height: fit-content;
    @include _h660 {
      height: 100%;
    }
    overflow: hidden;
    overflow-y: auto;
    padding: 0 40px 40px;
    background: $white;

    &-select {
      display: flex;
      border-bottom: 1px solid $border;
      margin: 0 -40px 30px;

      &__item {
        width: 50%;
        background: $inactive;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $border;
        @include font(24, 30, bold, $inactiveText);

        &--active {
          background: $white;
          color: $text;
        }
      }
    }

    &-socials {
      margin: 10px -5px 20px;
      display: flex;
      flex-flow: row wrap;

      &-item {
        border-radius: 8px;
        display: flex;
        margin: 5px;
        height: 54px;
        align-items: center;
        @include font(16, 19, bold, $white);

        & > svg {
          margin-right: 10px;
        }

        &_google {
          padding: 0 26px 0 14px;
          color: $text;
          border: 1px solid $border1;

          & > svg {
            height: 38px;
            width: 38px;
          }
        }

        &_vk {
          background: $socialVk;
          padding: 0 21.5px 0 9.5px;

          & > svg {
            height: 45px;
            width: 45px;
          }
        }

        &_ok {
          padding: 0 15.5px 0 3.5px;
          background: $socialOk
        }

        &_fb {
          padding: 0 18px 0 6px;
          background: $socialFb;
        }

        &_more {
          padding: 0 19.5px;
          color: $text;
        }
      }
    }

    &-items {
      margin-top: 15px;
    }
  }

  &-content {
    margin-top: 90px;
    padding-right: 40px;

    @include _h768 {
      margin-top: 140px;
    }
    @include _1600 {
      padding-left: 40px;
    }
    width: 747px;
    z-index: 1;
  }

  &-header {
    position: absolute;
    left: 49px;
    top: 49px;

    &__title {
      width: 215.54px;
      height: 19.01px;
      fill: #fff;
    }

    &__made {
      margin-top: 13.22px;
      display: flex;
      text-decoration: none;
    }

    &__description {
      color: $white;
      margin-right: 8.81px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &-wrapper {
    flex: 1;
    height: 100vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @include _h768 {
      align-items: unset;
    }
  }

  &-page {
    width: 1514px;
    display: flex;
    padding-left: 82px;
    justify-content: space-between;
    @include _1600 {
      padding-left: 0;
      width: 100%;
    }

  }
}
</style>