import {createRouter, createWebHistory} from "vue-router";
import Auth from "../views/Auth.vue";
import Home from "../views/Home.vue";
import ProjectCreate from "../views/project/Create";
import VkConfirm from "../views/socialAuth/VkConfirm";
import OkConfirm from "../views/socialAuth/OkConfirm";
import ImConfirm from "../views/socialAuth/ImConfirm";
import ProjectPublications from "../views/project/Publications";
import ProjectSettings from "../views/project/Settings";
import SettingsStages from "../views/project/SettingsStages";
import UserPersonalData from "../views/user/PersonalData";
import PersonalData from "../views/project/PersonalData";
import Privacy from "../views/project/Privacy";
import Team from "../views/project/Team";
import {API} from "@/plugins/axios";
import {store} from "@/store"
import {nextTick} from 'vue';
import PublicationsCreatev2 from "../views/project/PublicationsCreatev2";
import SettingsCaptions from "../views/project/SettingsCaptions";
import SettingsQuickLinks from "../views/project/SettingsQuickLinks";
import VkConfirmGroupAccess from "../views/socialAuth/VkConfirmGroupAccess";
import ProjectNotifications from "../views/project/Notifications";
import ClientView from "../views/ClientView";
import Admin from "@/views/Admin";
import AdminProject from "@/views/AdminProject.vue";
import AdminPublication from "@/views/AdminPublication.vue";
// import Messages from "../views/project/Messages";
import ProjectModel from "../store/models/Project";
import settingsAccountAdd from "../views/project/SettingsAccountAdd";
import Kanban from "../views/project/Kanban";
import Archive from "../views/Archive";
import Report from "../views/project/Report";
import SettingsB24 from "@/views/project/SettingsB24";
import B24ClientAuth from "@/views/B24ClientAuth";
import B24AppPrivacy from "@/views/B24AppPrivacy";
import B24AppLicence from "@/views/B24AppLicence";

let routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Проекты",
        },
    },
    {
      path: '/debug/',
      name: "AdminDebug",
        component: Admin,
        meta: {
          title: "Панель отладки"
        }
    },
    {
      path: '/debug/:project',
      name: "AdminDebugProject",
        component: AdminProject,
        meta: {
          title: "Панель отладки"
        }
    },
     {
      path: '/debug/:project/:publication',
      name: "AdminDebugPublication",
        component: AdminPublication,
        meta: {
          title: "Панель отладки"
        }
    },
    {
        path: "/b24/privacy",
        name: "B24Privacy",
        component: B24AppPrivacy,
        meta: {
            title: "Политика конфиденциальности",
        },
    },
    {
        path: "/b24/licence",
        name: "B24Licence",
        component: B24AppLicence,
        meta: {
            title: "Лицензионное соглашение",
        },
    },
    {
        path: "/client/:projectUUID",
        name: "ClientView",
        component: ClientView,
        props: (route) => {
            return {
                projectUUID: route.params.projectUUID,
                cleanPage: true,
            };
        },
        meta: {
            title: "Согласование публикаций",
        },
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Проекты",
        },
    },
    {
        path: "/archive",
        name: "archive",
        component: Archive,
        meta: {
            title: "Архив",
        },
    },
    {
        path: "/project/:currentProject/notifications",
        name: "notifications",
        component: ProjectNotifications,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
        meta: {
            title: "Уведомления",
        },
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: Privacy,
        meta: {
            title: "Политика конфиденциальности",
        },
    },
    {
        path: "/personal-data",
        name: "PersonalData",
        component: PersonalData,
        meta: {
            title: "Соглашение на обработку персональных данных",
        },
    },
    {
        path: "/project/create",
        name: "ProjectCreate",
        component: ProjectCreate,
        meta: {
            title: "Создание проекта",
        },
    },
    {
        path: "/project/:currentProject/publications/create",
        name: "ProjectPublicationsCreate",
        component: PublicationsCreatev2,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
                type: route.query.type === 'story' ? 2 : 1,
            };
        },
        meta: {
            title: "Создание публикации",
        },
    },
    {
        path: "/project/:currentProject/publications/edit/:publication",
        name: "ProjectPublicationsEdit",
        component: PublicationsCreatev2,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
                publication: Number(route.params.publication),
            };
        },
        meta: {
            title: "Редактирование публикации",
        },
    },
    {
        path: "/project/:currentProject/account-add/",
        name: "SettingsAccountAdd",
        component: settingsAccountAdd,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
        meta: {
            title: "Добавление аккаунта",
        },
    },
    {
        path: "/auth",
        name: "Auth",
        component: Auth,
        meta: {
            title: "Авторизация",
        },
    },
    {
        path: "/project/:currentProject/kanban/",
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
        meta: {
            title: "Канбан",
        },
        name: "ProjectKanban",
        component: Kanban,
    },
    {
        path: "/auth/vk.userAuth",
        name: "VkConfirm",
        component: VkConfirm,
        meta: {
            title: "Подтверждение авторизации",
        },
    },
    {
        path: "/auth/ok.userAuth",
        name: "OkConfirm",
        component: OkConfirm,
        meta: {
            title: "Подтверждение авторизации",
        },
    },
    {
        path: "/auth/im.userAuth",
        name: "ImConfirm",
        component: ImConfirm,
        meta: {
            title: "Подтверждение авторизации",
        },
    },
    {
        path: "/project/account/extend",
        name: "ConfirmGroupExtend",
        component: VkConfirmGroupAccess,
        meta: {
            title: "Расширение прав аккаунта",
        },
    },
    {
        path: "/user",
        name: "UserPersonalData",
        component: UserPersonalData,
        meta: {
            title: "Персональные данные",
        },
    },
];

export let projectRoutes = [
    {
        path: "/project/:currentProject/publications",
        name: "ProjectPublications",
        verbose_name: "Публикации",
        icon_name: "PublicationsIcon",
        component: ProjectPublications,
        meta: {
            title: "Публикации",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    // {
    //   path: "/project/:currentProject/messages",
    //   name: "ProjectMessenger",
    //   verbose_name: "Сообщения",
    //   component: Messages,
    //   props: (route) => {
    //     return {
    //       currentProject: Number(route.params.currentProject),
    //     };
    //   },
    // },
    {
        path: "/project/:currentProject/team",
        name: "ProjectTeam",
        icon_name: "TeamIcon",
        verbose_name: "Команда",
        component: Team,
        meta: {
            title: "Команда",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/settings",
        name: "ProjectSettings",
        icon_name: "SettingsIcon",
        verbose_name: "Настройки",
        component: ProjectSettings,
        meta: {
            title: "Настройки проекта",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/report",
        name: "ProjectReport",
        icon_name: "ReportIcon",
        verbose_name: "Отчет",
        component: Report,
        meta: {
            title: "Отчеты",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        }
    },
];

export const projectSettingsRoutes = [
    {
        path: "/project/:currentProject/settings",
        name: "ProjectSettings",
        verbose_name: "Аккаунты и настройки",
        component: ProjectSettings,
        meta: {
            title: "Настройки проекта",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/settings/stages",
        name: "SettingsStages",
        verbose_name: "Этапы работы",
        component: SettingsStages,
        meta: {
            title: "Этапы работы",
        },
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/settings/captions",
        name: "SettingsCaptions",
        verbose_name: "Подписи",
        meta: {
            title: "Подписи",
        },
        component: SettingsCaptions,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/settings/ql",
        name: "SettingsQuickLinks",
        verbose_name: "Быстрые ссылки",
        meta: {
            title: "Быстрые ссылки",
        },
        component: SettingsQuickLinks,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
    {
        path: "/project/:currentProject/settings/b24",
        name: "SettingsQuickLinks",
        verbose_name: "Интеграция Bitrix24",
        meta: {
            title: "Интеграция Bitrix24",
        },
        component: SettingsB24,
        props: (route) => {
            return {
                currentProject: Number(route.params.currentProject),
            };
        },
    },
];

routes = routes.concat(...[
    {
        path: "/b24_client_auth/:token",
        name: "B24ClientAuth",
        verbose_name: "Авторизация B24",
        component: B24ClientAuth,
        meta: {
            title: "Авторизация B24",
        },
        props: (route) => {
            return {
                token: route.params.token,
            };
        },
    },
], ...projectRoutes, ...projectSettingsRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
const getProjectData = (id) => {
    let fd = new FormData()
    fd.append('project', id)
    API.post('/projects/info', fd).then(
        response => ProjectModel.insert({data: response.data})
    )
}
const UNAUTH_ROUTES = ["VkConfirm", "OkConfirm", 'ClientView', 'B24Privacy', 'B24Licence', 'B24ClientAuth'];
const DEFAULT_TITLE = "#ВсеПоПлану"

router.beforeEach(async (to, from, next) => {
    const isAuthCompleted = localStorage.authCompleted === "true" && localStorage.token,
        projectId = to.params['currentProject'];
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
    if (projectId) {
        store.dispatch("projectSelect", parseInt(projectId));
        if (!ProjectModel.find(projectId)) {
            getProjectData(projectId)
        }
    }
    if (UNAUTH_ROUTES.indexOf(to.name) !== -1) next();
    else if (to.name === "Auth" && isAuthCompleted) {
        if (to.query.invite) {
            let inviteData = new FormData();
            inviteData.append("invite", to.query.invite);
            await API.post("projects/team/use_team_invite", inviteData).then(
                (response) => {
                    next({
                        name: "ProjectPublications",
                        params: {currentProject: response.data.project},
                    });
                }
            );
        } else next({name: "Home"});
    } else if (to.name !== "Auth" && !isAuthCompleted) next({name: "Auth"});
    else next();
});

export default router;
