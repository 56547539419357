<script>
    import BaseIcon from "./icons/BaseIcon";
    import VkIcon from "./icons/socialBigVk";
    import ImIcon from "./icons/socialBigInsta";
    import OkIcon from "./icons/socialBigOk";
    import TgIcon from "./icons/socialBigTg"
    import BaseInput from "./BaseInput";

    import { popupAuth, windowParameters } from "../api";

    export default {
        data() {
            return {
                tgModal: {
                    token: "",
                    show: false,
                }
            }
        },
        props: {
        },
        emits: ['socialSelect', 'success', 'error', ''],
        components: {
            BaseIcon,
            VkIcon,
            ImIcon,
            OkIcon,
            TgIcon,
            BaseInput,
        },
        methods: {
            okAuth() {
                this.$API.post("ok.userAuth", "state=project").then((response) => {
                    let wnd = window.open(
                        response.data.redirectLink,
                        "okPopup",
                        windowParameters
                    );
                    popupAuth(
                        wnd,
                        "tempAuthOk",
                        () => {
                            this.$store.dispatch("authConfirm");
                            this.$emit("socialSelect", "ok", localStorage.tempAuthOk);
                            this.$emit("success");
                            localStorage.okAuth = false;
                        },
                        () => {
                            this.$emit('error')
                        }
                    );
                });
            },
            vkAuth() {
              console.log(0)
                this.$API.post("vk.userAuth", "state=project").then((response) => {
                    let wnd = window.open(
                        response.data.redirectLink,
                        "vkPopup",
                        windowParameters
                    );
                    popupAuth(
                        wnd,
                        "tempAuthVk",
                        () => {
                            console.log(1)
                            // this.$store.dispatch("authConfirm");
                            console.log(2)
                            this.$emit("socialSelect", "vk", localStorage.tempAuthVk);
                            console.log(3)
                            console.log(4)
                            localStorage.vkAuth = false;
                            console.log(5)
                            this.$emit("success");
                        },
                        () => {
                          console.log(6)
                            this.$emit('error')
                          console.log(7)
                        }
                    );
                });
            },
            imAuth() {
                this.$API.post("projects/accounts/auth/im", "state=project")
                    .then((response) => {
                        let wnd = window.open(
                            response.data.redirectLink,
                            "imPopup",
                            windowParameters
                        );
                        popupAuth(
                            wnd,
                            "tempAuthIm",
                            () => {
                                this.$store.dispatch("authConfirm");
                                this.$emit("socialSelect", "im", localStorage.tempAuthIm);
                                this.$emit("success");
                                localStorage.imAuth = false;
                            },
                            () => {
                                this.$emit('error')
                            }
                        );
                    });
            },
            tgAuth() {
                this.$store.dispatch("authConfirm");
                this.$emit("socialSelect", "tg", this.tgModal.token);
                this.$emit("success");
                localStorage.tgAuth = false;
                this.tgModal.show = false;
                this.tgModal.token = ""
            },

        },
    }
</script>

<template>
    <div class="sp-account-add__socials">
        <div class="sp-account-add-social">
            <div class="sp-account-add-social__content">
                <base-icon width="50"
                           height="50"
                           icon-name=""
                >
                    <vk-icon/>
                </base-icon>
                Вконтакте
            </div>
            <div class="sp-account-add-social__connect sp-btn sp-btn--main_outlined"
                 @click="vkAuth"
            >
                Подключить
            </div>
        </div>
        <div class="sp-account-add-social">
            <div class="sp-account-add-social__content">
                <base-icon width="50"
                           height="50"
                           icon-name=""
                >
                    <tg-icon/>
                </base-icon>
                Телеграм
            </div>
            <div class="sp-account-add-social__connect sp-btn sp-btn--main_outlined"
                 @click="tgModal.show = true"
            >
                Подключить
            </div>
        </div>
        <div class="sp-account-add-social">
            <div class="sp-account-add-social__content">
                <base-icon width="50"
                           height="50"
                           icon-name=""
                >
                    <ok-icon/>
                </base-icon>
                Одноклассники
            </div>
            <div class="sp-account-add-social__connect sp-btn sp-btn--main_outlined"
                 @click="okAuth"
            >
                Подключить
            </div>
        </div>
        <div class="sp-account-add-social sp-account-add-social__inactive">
            <div class="sp-account-add-social__content">
                <base-icon width="50"
                           height="50"
                           icon-name=""
                >
                    <im-icon/>
                </base-icon>
                Instagram Business
            </div>
            <div class="sp-account-add-social__connect sp-btn sp-btn--main_outlined"

            >
                Подключить
            </div>
        </div>
    </div>
    <Modal v-model="tgModal.show"
           :close="() => (tgModal.show = false)"
    >
        <div class="sp-modal sp-modal-tg">
            <p class="sp-modal__title sp-modal-tg__title">Для подключения учетных записей Telegram введите API-токен бота, вы можете получить токен у BotFather.</p>
            <base-input name="token" label="API-токен"
                        v-model="tgModal.token"
            >
            </base-input>
            <button class="sp-btn sp-btn--main sp-modal-tg__btn"
                    @click.prevent="tgAuth"
            >
                Подключить
            </button>
        </div>
    </Modal>
</template>

<style lang="scss">
    @import "/src/assets/scss/env";
    .sp-modal-tg {
        padding: 30px 40px;
        width: 600px;
        &__title {
            @include font(18,20,bold);
        }
        &__btn {
            height: 40px;
            margin-top: 15px;
        }
    }
    .sp-account-add {
        &__socials {
            display: flex;
            flex-flow: row wrap;
            margin: 15px -56px -15px;
        }
        &-social {
            width: 460px;
            justify-content: space-between;
            align-items: center;
            display: flex;
            margin: 15px 56px;
            &__inactive {
                pointer-events: none;
                user-select: none;

                opacity: .7;
            }
            &__content {
                display: flex;
                align-items: center;
                @include font(16,20,bold);
                & > svg {
                    margin-right: 19px;
                }
            }
            &__connect {
                height: 45px;
                width: 164px;
                @include font(14,17,bold,$mainBtn);
            }
        }
    }
</style>